
.sales-content{
    display: flex;
    flex-direction: column;
    height: 100%;
    .sales-title{
        font-size: 24px;
        color: #333;
        padding: 20px;
    }
    .where-address{
        &:before{
            content: "*";
            position: absolute;
            color: #E84932;
            margin-top: 12px;
            margin-left: 35px
        }
    }
    ::v-deep .el-form{
        height: 100%;
    }
    .dialog-footer {
        text-align: center;
        margin: 20px 0;
    }

}
